import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import { get } from '@/utils/get'
import { sectionSerializer } from '@/prismic/utils/sectionSerializer'
import { eventSerializer } from '@/prismic/utils/eventSerializer'
import PageTemplate from '@/templates/Page'

const PagePrismic = ({ data }) => {
  const { prismicPage, prismicEvents } = data

  const events = eventSerializer(prismicEvents.edges)
  const slices = get(prismicPage, 'data.body') || []
  const sections = sectionSerializer(slices, events)

  return <PageTemplate sections={sections} />
}

PagePrismic.propTypes = {
  data: PropTypes.object.isRequired,
}

export default withPreview(PagePrismic)

// filtering by date
// https://harrycresswell.com/articles/filtering-content-in-gatsby-by-date-and-deadline/

export const query = graphql`
  query PageByID($id: String!, $currentDate: Date!) {
    prismicPage(id: { eq: $id }) {
      uid
      id
      type
      data {
        title
        body {
          ... on PrismicPageBodyContact {
            id
            slice_type
            primary {
              uid
              video {
                url
              }
              image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
              heading {
                text
              }
              contact_details {
                raw
              }
              hours_monday
              hours_tuesday
              hours_wednesday
              hours_thursday
              hours_friday
              hours_saturday
              hours_sunday
            }
          }
          ... on PrismicPageBodyEvents {
            id
            slice_type
            primary {
              uid
              no_events_message {
                raw
              }
            }
          }
          ... on PrismicPageBodyImageLayout {
            id
            slice_type
            primary {
              uid
            }
            items {
              image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicPageBodyCta {
            id
            slice_type
            primary {
              uid
              heading_text
              content {
                raw
              }
              link {
                document {
                  ... on PrismicPage {
                    uid
                    type
                  }
                }
                link_type
                target
                url
              }
              link_label
            }
          }
          ... on PrismicPageBodyMenu {
            id
            slice_type
            primary {
              uid
              footer {
                raw
              }
              footer_link {
                document {
                  ... on PrismicPage {
                    uid
                    type
                  }
                }
                link_type
                target
                url
              }
              footer_link_label
            }
            items {
              menu {
                document {
                  ... on PrismicMenu {
                    id
                    data {
                      title
                      emoji
                      body {
                        ... on PrismicMenuBodyMenuSection {
                          id
                          slice_type
                          primary {
                            heading
                            desktop_position
                          }
                          items {
                            title
                            subtitle
                            price
                            description {
                              raw
                            }
                          }
                        }
                        ... on PrismicMenuBodyMenuText {
                          id
                          slice_type
                          primary {
                            heading
                            content {
                              raw
                            }
                            desktop_position
                          }
                        }
                        ... on PrismicMenuBodyMenuText2Column {
                          id
                          slice_type
                          primary {
                            desktop_position
                          }
                          items {
                            heading
                            content {
                              raw
                            }
                            desktop_position
                          }
                        }
                        ... on PrismicMenuBodyMenuCallout {
                          id
                          slice_type
                          primary {
                            desktop_position
                            heading
                            subtitle
                            price
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicEvents: allPrismicEvent(
      filter: { data: { date: { gte: $currentDate } } }
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          id
          uid
          type
          data {
            title
            date(formatString: "YYYY-MM-DD", locale: "en-GB")
            image {
              fluid(maxWidth: 2000) {
                ...GatsbyPrismicImageFluid_noBase64
              }
              url
              alt
              dimensions {
                width
                height
              }
            }
            description {
              raw
            }
            link {
              link_type
              target
              url
            }
            link_label
          }
        }
      }
    }
  }
`
