import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import { sectionSerializer } from '@/prismic/utils/sectionSerializer'
import PageTemplate from '@/templates/Page'

const PagePrismic = ({ data }) => {
  const { prismicMenu } = data
  const slices = [
    {
      slice_type: 'menu',
      id: prismicMenu.uid,
      items: [
        {
          menu: { document: { ...prismicMenu } },
        },
      ],
    },
  ]
  const sections = sectionSerializer(slices)

  return <PageTemplate sections={sections} />
}

PagePrismic.propTypes = {
  data: PropTypes.object.isRequired,
}

export default withPreview(PagePrismic)

export const query = graphql`
  query MenuByID($id: String!) {
    prismicMenu(id: { eq: $id }) {
      uid
      id
      data {
        title
        emoji
        body {
          ... on PrismicMenuBodyMenuSection {
            id
            slice_type
            primary {
              heading
              desktop_position
            }
            items {
              title
              subtitle
              price
              description {
                raw
              }
            }
          }
          ... on PrismicMenuBodyMenuText {
            id
            slice_type
            primary {
              heading
              content {
                raw
              }
              desktop_position
            }
          }
          ... on PrismicMenuBodyMenuText2Column {
            id
            slice_type
            primary {
              desktop_position
            }
            items {
              heading
              content {
                raw
              }
              desktop_position
            }
          }
          ... on PrismicMenuBodyMenuCallout {
            id
            slice_type
            primary {
              desktop_position
              heading
              subtitle
              price
            }
          }
        }
      }
    }
  }
`
