/**
 * Process event data
 *
 * @param {array} data
 * @param {string} now (date)
 */

import dayjs from 'dayjs'
import { get } from '../../utils/get'
import { getImage } from './getImage'
import { getLink } from './getLink'
import { getRichText } from './getRichText'
import { linkResolver } from './linkResolver'

export const eventSerializer = (data, limit = 10) => {
  const events = []

  data.forEach(({ node }) => {
    const dateFormat = 'YYYY-MM-DD' // make sure this is same as page query
    const eventDate = get(node, 'data.date') || dayjs().format(dateFormat)
    const isPastEvent = dayjs(eventDate, dateFormat).isBefore(dayjs(), 'day')

    if (!isPastEvent) {
      events.push({
        id: node.id,
        title: get(node, 'data.title'),
        path: linkResolver(node),
        image: getImage(node, 'data.image', 'fluid'),
        link: get(node, 'data.link.url')
          ? getLink(node.data.link, node.data.link_label || 'Book')
          : undefined,
        description: getRichText(node, 'data.description.raw'),
      })
    }
  })

  return events.slice(0, limit)
}
