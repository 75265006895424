// extracted by mini-css-extract-plugin
export var aniSpin = "Error-module--ani-spin--A5Pnp";
export var black = "#000";
export var el = "Error-module--el--RDWuW";
export var el__container = "Error-module--el__container--7WSJ5";
export var el__heading = "Error-module--el__heading--mczYG";
export var el__message = "Error-module--el__message--dZ+e5";
export var largeDesktop = "1400px";
export var largePhone = "480px";
export var mediumDesktop = "1300px";
export var smallDesktop = "1100px";
export var smallPhone = "320px";
export var tabletLandscape = "1024px";
export var tabletPortrait = "768px";
export var white = "#fff";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";